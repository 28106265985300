import { apiHelper } from '@/helpers/apiHelper'
import API from '@axios'

const API_URL = '/api/crm/activities'

export default {
  ...apiHelper(API_URL),
  getCustomFields() {
    return API.get(`${API_URL}/custom_fields`)
  },
}
