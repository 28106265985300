<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="!!resourceId"
    :form-title="formTitle"
    :loading="loading"
    :saving="saving"
    @close="close"
    @submit="save"
  >
    <form
      id="companyForm"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <FormFullEditLink v-if="!!resourceId" :resource-link="resourceLink" />
      <prozess-field-wrapper
        v-if="!resourceId && (company && company.isNew) "
        :icon="MetastatusTypeIcons['status']"
        :error="$hasError('statusId')"
      >
        <prozess-select
          v-model="form.statusId"
          style="flex: 1"
          :placeholder="$t('Status') "
          :options="statusOptions"
          :reduce="(option) => option.metaStatusId"
          label="metaStatusName"
          name="statusId"
          field="statusId"
          :clearable="false"
        />
      </prozess-field-wrapper>
      <label v-if="company">
        {{ $t('Name') }}
      </label>
      <CompanySearch
        v-model="company"
        :disabled="disableCompanySearch"
        :error="$hasError('name')"
        class="mb-2"
      />
      <div v-for="field in fieldMetadata" :key="`field__${field.key}`">
        <AppDynamicField
          v-if="!fieldHidden(field)"
          v-model="form[field.key]"
          :field="field"
          :error="$hasError(field.key)"
          :resourceId="resourceId"
        />
      </div>
      <template v-if="pivotFieldMetadata.length">
        <h4 class="mb-2">
          {{ $t('Company-Contact Custom Fields') }}
        </h4>
        <div
          v-for="field in pivotFieldMetadata"
          :key="`company-contact__${field.key}`"
        >
          <AppDynamicField
            v-model="pivotForm[field.key]"
            :field="field"
            :error="$hasError(field.key, null, pivotErrors)"
            :resourceId="resourceId"
          />
        </div>
      </template>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>
<script>
import { renameKeys } from 'lodash-contrib'
import { Entity, Schema } from '@/constants/app'
import { fillableOnly } from '@/helpers/field'
import {
  companyContactRelSchema,
  companyContactSchema,
} from '@/schema/company'
import { formatAsPayload, standardKeyMapping } from '@/helpers/companyForm'
import { advancedFieldValue } from '@/helpers/customFields'
import AppDynamicField from '@/components/shared/AppDynamicField.vue'
import advancedFieldMgmtService from '@/services/advancedFieldMgmt'
import companyContactService from '@/services/companyContact'
import CompanySearch from '@/views/shared/CompanySearch.vue'
import companyService from '@/services/company'
import FormFullEditLink from '@/components/shared/FormFullEditLink.vue'
import { MetastatusTypeIcons } from '@/constants/metastatusTypes'

export default {
  components: {
    AppDynamicField,
    CompanySearch,
    FormFullEditLink,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },

    companyId: {
      type: [String, Number],
      default: null,
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      MetastatusTypeIcons,
      company: null,
      errors: [],
      pivotErrors: [],
      fieldMetadata: [],
      form: {},
      pivotForm: {},
      loading: true,
      pivotCustomFields: {},
      pivotFieldMetadata: [],
      saving: false,
    }
  },

  computed: {
    formTitle() {
      return this.resourceId
        ? 'Edit Related Company'
        : 'Add Related Company'
    },

    disableCompanySearch() {
      return !!this.resourceId && !!this.company
    },

    companyExists() {
      return this.company && !this.company.isNew
    },

    resourceLink() {
      return this.$router.resolve({
        name: 'company-view',
        params: { id: this.company?.value?.uuid, tab: 'contacts' },
      }).href
    },

    service() {
      return companyContactService(this.$route.params.id, 'contact')
    },
  },

  watch: {
    company(company) {
      this.form = {
        ...this.form,
        name: null,
      }

      if (!company) {
        return
      }

      this.form.name = company.isNew ? company.value : company.value.name
    },

    visible: {
      handler(visible) {
        if (visible) {
          this.reset()
          this.getInitialData()
        }
      },

      immediate: true,
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    fieldHidden(field) {
      if (field.key === 'name') {
        return true
      }

      if (this.companyExists) {
        return true
      }

      return false
    },

    formatFormAsPayload() {
      const payload = {
        customFields: this.pivotForm,
        role: this.pivotForm.role,
        statusId: this.form.statusId,
      }

      if (this.company?.value?.uuid) {
        payload.existingCompanyUuid = this.company?.value?.uuid
      } else {
        payload.newCompany = {
          ...formatAsPayload(this.form, this.fieldMetadata),
          name: this.company?.value,
        }
      }

      payload.customFields = this.pivotForm

      return payload
    },

    formatAsFormData(response) {
      const customFields = response.customFieldData.reduce((acc, item) => {
        acc[item.key] = advancedFieldValue(item.value, {
          toArrayIfMulti: true,
        })
        return acc
      }, {})

      const form = this.$lodash.pick(response, Object.keys(standardKeyMapping))

      this.form = {
        ...renameKeys(form, standardKeyMapping),
        ...customFields,
      }
    },

    async getAllFields() {
      const { response } = await this.$async(
        advancedFieldMgmtService.getFieldsByEntity({
          schema: Schema.CRM,
          table: Entity.COMPANY,
        }),
      )

      const fieldMetadata = fillableOnly(response.data).reduce((acc, item) => {
        if (item.key === 'name') {
          item.required = true
        }

        acc.push(item)

        // Force $set all fields to `pivotForm` object to make them reactive for v-models
        if (item.type === 'BOOL') {
          this.$set(this.form, item.key, false)
        } else this.$set(this.form, item.key, null)

        return acc
      }, [])

      this.fieldMetadata = this.$lodash.cloneDeep(fieldMetadata)
    },

    async getCompanyContactCustomFields() {
      const { response } = await this.$async(
        advancedFieldMgmtService.getFieldsByEntity({
          schema: Schema.CRM,
          table: Entity.COMPANY_HAS_CONTACT,
        }),
      )

      this.pivotFieldMetadata = fillableOnly(response.data).reduce(
        (acc, item) => {
          if (item.key === 'role') {
            item.required = true
          }

          acc.push(item)

          // Force $set all fields to `pivotForm` object to make them reactive for v-models
          this.$set(this.pivotForm, item.key, null)

          return acc
        },
        [],
      )
    },

    async getInitialData() {
      this.loading = true

      await Promise.all([
        this.getCompanyContactCustomFields(),
        this.getAllFields(),
      ])

      if (this.resourceId) {
        await this.getResource()
        await this.getCompanyContactData()
      }

      this.loading = false
    },

    async getResource() {
      const { response } = await this.$async(
        companyService.getOne(this.companyId),
      )

      const { data: company } = response

      this.company = {
        isNew: false,
        label: company.name,
        selectedLabel: company.name,
        value: company,
      }

      this.formatAsFormData(company)
    },

    async getCompanyContactData() {
      const { response } = await this.$async(
        this.service.getOneByCompany(this.companyId, this.resourceId),
      )

      const { data } = response

      this.pivotForm = data.customFieldData.reduce((acc, item) => {
        acc[item.key] = advancedFieldValue(item.value, {
          toArrayIfMulti: true,
        })
        return acc
      }, {})

      this.pivotForm.role = data.role
    },

    reset() {
      this.company = null
      this.errors = []
      this.form = {}
      this.loading = false
      this.saving = false
    },

    async save() {
      // The line `item.key === 'name'` makes sure that the name field will be validated.
      const fieldMetadata = this.fieldMetadata.filter(
        item => !this.fieldHidden(item) || item.key === 'name',
      )

      const form = this.$lodash.cloneDeep(this.form)

      this.errors = await this.yupValidate(
        companyContactSchema(fieldMetadata),
        form,
      )

      this.pivotErrors = await this.yupValidate(
        companyContactRelSchema(this.pivotFieldMetadata),
        this.pivotForm,
      )

      if (!!this.errors.length || !!this.pivotErrors.length) {
        return false
      }

      this.saving = true

      const payload = this.formatFormAsPayload()

      const request = this.resourceId
        ? this.service.updateRelation(this.companyId, this.resourceId, payload)
        : this.service.store(payload)

      const { error } = await this.$async(request)

      this.saving = false

      if (!error) {
        this.$emit('saved')
        this.close()
      }
    },
  },
}
</script>
