<template>
  <b-card
    no-body
    class="system-settings-card prozess-custom-entity-data"
  >
    <div class="m-2 custom-entity-table">
      <SidebarForm
        :resource-id="resourceId"
        :visible="showForm"
        @saved="resetFetch()"
        @close="showForm = false"
      />
      <div class="mb-2 d-flex table-actions">
        <b-button
          id="add-activity-button"
          variant="primary"
          class="mr-1"
          :class="{ 'icon-disabled': !canLog }"
          @click="canLog && toggleModal()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('Log new Activity') }}</span>
        </b-button>
        <b-tooltip target="add-activity-button">
          {{ canLog ? $t('Log new Activity') : $t('no-action-permission') }}
        </b-tooltip>
      </div>

      <div class="timeline-container">
        <div
          v-infinite-scroll="fetchLists"
          class="infinite-scroll-activity-list"
          infinite-scroll-disabled="loading"
          infinite-scroll-distance="0"
          :infinite-scroll-immediate-check="false"
        >
          <timeline
            v-if="timelines.length"
            timeline-bg="#2E3D46"
          >
            <div
              v-for="(timeline, index) in timelines"
              :key="index"
              class="timeline-item form-sidebar__users"
            >
              <div
                class="timeline-circle"
                style="background: #28c76f"
              />
              <div class="timeline-content">
                <div class="timeline-content__title">
                  <span>{{ $t(timeline.title) }}</span>
                  <div class="timeline-content--actions">
                    <feather-icon
                      :id="`edit-${index}-activity`"
                      icon="EditIcon"
                      size="14"
                      :class="{ 'icon-disabled': !canLog }"
                      class="mr-1 cursor-pointer"
                      @click="canLog && edit(timeline.uuid)"
                    />

                    <b-tooltip
                      v-if="timeline.uuid"
                      :target="`edit-${index}-activity`"
                    >
                      {{ canLog ? $t('Edit Activity') : $t('no-action-permission') }}
                    </b-tooltip>
                    <feather-icon
                      :id="`delete-${timeline.uuid}-activity-button`"
                      icon="TrashIcon"
                      size="14"
                      :class="{ 'icon-disabled': !canLog }"
                      class="cursor-pointer"
                      @click="canLog && remove(timeline.uuid)"
                    />

                    <b-tooltip :target="`delete-${timeline.uuid}-activity-button`">
                      {{ canLog ? $t('Delete Activity') : $t('no-action-permission') }}
                    </b-tooltip>
                  </div>
                </div>
                <div class="timeline-content__body">
                  <b-container fluid>
                    <b-row class="tw-mb-2">
                      <b-col
                        cols="2"
                        class="pl-0"
                      >
                        {{ $t('Category') }}
                      </b-col>
                      <b-col
                        cols="10"
                        class="pl-0"
                      >
                        <feather-icon
                          class="mr-1"
                          :icon="timeline.categorySingleResponse.icon || ''"
                        />
                        {{ $t(timeline.categorySingleResponse.metaStatusName) }}
                      </b-col>
                    </b-row>
                    <b-row class="tw-mb-2">
                      <b-col
                        cols="2"
                        class="pl-0"
                      >{{ $t('Description') }}</b-col>
                      <b-col
                        cols="10"
                        class="pl-0"
                      >
                        {{ $t(timeline.description) || '--' }}
                      </b-col>
                    </b-row>
                    <b-row class="tw-mb-2">
                      <b-col
                        cols="2"
                        class="pl-0"
                      >
                        {{ $t('Activity Date') }}
                      </b-col>
                      <b-col
                        cols="10"
                        class="pl-0"
                      >
                        <span class="text-success">{{ dayjs(timeline.dateTime).format('MM-DD-YYYY') }}</span>
                      </b-col>
                    </b-row>
                    <b-row class="tw-mb-2">
                      <b-col
                        cols="2"
                        class="pl-0"
                      >
                        {{ $t('Related Users') }}
                      </b-col>
                      <b-col
                        cols="10"
                        class="pl-0"
                      >
                        <b-badge
                          v-for="user in timeline.relatedUsers"
                          :key="user.userUuid"
                          pill
                          variant="primary"
                          class="tw-font-semibold tw-mr-2 tw-mb-1"
                        >
                          {{ `${user.firstName} ${user.lastName}` }}
                        </b-badge>
                      </b-col>
                    </b-row>
                    <b-row class="tw-mb-2">
                      <b-col
                        cols="2"
                        class="pl-0"
                      >
                        {{ $t('Custom Field') }}
                      </b-col>
                      <b-col
                        cols="10"
                        class="pl-0"
                      >
                        <div class="tw-mb-2 row-flex-justify-space-between">
                          <div>
                            {{ timeline.customFields[0].label }} :
                            <span class="tw-font-bold">
                              {{ timeline.customFields[0].value || $t('none') }}
                            </span>
                          </div>

                          <div class="prozess-company-contact__item-show-more mr-5">
                            <div @click="timeline.isCollapse = !timeline.isCollapse">
                              {{ $t(`Show ${timeline.isCollapse ? 'Less' : 'More'}`) }}
                              <feather-icon :icon="timeline.isCollapse ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                            </div>
                          </div>
                        </div>
                        <template v-if="timeline.isCollapse">
                          <div
                            v-for="(field, fieldIndex) in timeline.customFields"
                            :key="field.uuid"
                            class="tw-mb-2"
                          >
                            <div v-if="fieldIndex !== 0">
                              {{ field.label }} :
                              <span class="tw-font-bold">{{ field.value || $t('none') }}</span>
                            </div>
                          </div>
                        </template>
                      </b-col>
                    </b-row>
                  </b-container>
                </div>
              </div>
            </div>
          </timeline>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import SidebarForm from '@/components/Activity/ActivityForm.vue'
import { Timeline } from 'vue-cute-timeline'
import 'vue-cute-timeline/dist/index.css'
import activityService from '@/services/activity'
import { swalConfirmDeleteOption } from '@/helpers/app'
import dayjs from 'dayjs'

export default {
  components: {
    SidebarForm,
    Timeline,
  },

  data() {
    return {
      resourceId: null,
      dayjs,
      showForm: false,
      loading: false,
      timelines: [],
      totalItems: 1,
      size: 5,
      page: 0,
    }
  },

  computed: {
    canLog() {
      return this.$can('Update', 'CRM')
    },
  },

  watch: {
   '$route.params': {
      immediate: true,
      handler(val) {
         if (this.$route?.params?.tab === 'activities') {
            this.fetchLists()
         }
      },
   },
  },

  methods: {
    remove(id) {
      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(result => {
        if (result.value) {
          this.deleteResource(id)
        }
      })
    },

    async deleteResource(id) {
      const { response } = await this.$async(activityService.destroy(id))
      this.resetFetch()
    },
    toggleModal(resourceId = null) {
      this.resourceId = resourceId
      this.showForm = true
    },

    resetFetch() {
      this.page = 0
      this.totalItems = 1
      this.timelines = []
      this.fetchLists()
    },

    async fetchLists() {
      if (this.$route?.params?.tab !== 'activities') {
         return
      }
      this.loading = true
      if (this.totalItems > this.timelines.length) {
        const query = {
          page: this.page,
          size: this.size,
          sortBy: 'desc.dateTime',
        }

        const [timelineResponse, customFieldsResponse] = await Promise.all([activityService.getAll(query), activityService.getCustomFields()])

        // this.$async(activityService.getAll(query))

        const setLabel = customFields =>
         customFields.map(field => {
            const findLabel = customFieldsResponse.data.find(_field => _field.key === field.key)
            return {
              ...field,
              label: findLabel?.label,
            }
          })

        const timelines = timelineResponse.data.pageItems.map(item => ({
          customFields: setLabel(item.customFieldDataResponse),
          ...item,
          isCollapse: false,
        }))
        this.page++
        this.timelines = [...this.timelines, ...timelines]
        this.totalItems = timelineResponse.data.filteredItemsCount
      }
      this.loading = false
    },

    edit(id) {
      this.resourceId = id
      this.showForm = true
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/master-variables.scss';

.timeline-container {
  .timeline-item {
    > * {
      font-family: 'Montserrat', Helvetica, Arial, serif !important;
    }
    &:after {
      background: none !important;
    }
    border-color: transparent !important;
    padding-top: 10px;
    .timeline-circle {
      top: 40%;
      left: -39px;
      width: 20px;
      height: 20px;
    }
    .timeline-content {
      &__title {
        font-weight: 700;
        color: var(--colour--text-primary-600);
        padding-bottom: 0.5rem;
        margin-right: 2rem;
        display: flex;
        font-size: 1.3rem;
        justify-content: space-between;
        border-bottom: 2px solid var(--colour--input-border);
      }
      &__body {
        color: var(--colour--text-primary-600);
        padding-top: 1rem;
      }
    }
  }
}
</style>
