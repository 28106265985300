<template>
  <b-modal
    :id="companyContactsId"
    hide-footer
    centered
    no-fade
    size="sm"
    :title="$t('Company Contact')"
  >
    <div class="tw-my-4">
      <div v-if="items.length === 0">
        {{ $t('No available contacts') }}
      </div>
      <span v-for="(user, index) in items" :key="index">
        <div class="my-1">
          <feather-icon
            icon="UserIcon"
            class="mr-50"
          />
          {{ user.name }}
        </div>
      </span>
    </div>
  </b-modal>
</template>

<script>
import RootEvents from '@/constants/rootEvents'
import companyContactService from '@/services/companyContact'

export default {
  props: {
    companyContactsId: {
      type: String,
      required: true,
    },
    companyUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      items: [],
    }
  },
  computed: {
    service() {
      return companyContactService(this.$route.params.id)
    },
  },
  watch: {
    companyUuid: {
      handler(value) {
        if (value) this.getRelatedContacts()
      },
    },
  },
  methods: {
    async getRelatedContacts() {
      const response = await this.service.getRelatedUsers('crm', 'company', this.companyUuid)
      this.items = response.data
    },
  },
}
</script>
