var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('company-form',{attrs:{"visible":_vm.showForm,"resource-id":_vm.resourceId,"company-id":_vm.companyId,"status-options":_vm.statusOptions},on:{"saved":function($event){return _vm.getInitialData()},"close":_vm.onCloseForm}}),_c('company-contacts-modal',{attrs:{"company-contacts-id":_vm.companyContactsId,"company-uuid":_vm.companyUuid}}),_c('b-overlay',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"show":_vm.loading,"spinner-variant":"primary","rounded":"sm","variant":"transparent"}},[_c('div',{staticClass:"card resource-view__scrollable"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"mb-2"},[_c('button',{staticClass:"btn btn-primary d-flex justify-content-around align-items-center btn-secondary",class:{
              'icon-disabled': _vm.$can('Update', 'CRM') === false,
            },attrs:{"id":"create-button"},on:{"click":function($event){_vm.$can('Update', 'CRM') && _vm.create()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UserPlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Add Company')))])],1),_c('b-tooltip',{attrs:{"target":"create-button"}},[_vm._v(" "+_vm._s(_vm.$can('Update', 'CRM') === true ? _vm.$t('Add Company') : _vm.$t('You dont have permission to add company'))+" ")])],1),_c('b-table',{ref:"refTable",staticClass:"table-responsive",attrs:{"busy":_vm.loading,"items":_vm.items,"is-customizable":true,"fields":_vm.tableColumns,"no-local-sorting":"","responsive":"","primary-key":"id","show-empty":"","empty-text":_vm.$t('No matching records found')},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"head()",fn:function({ label }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(label)))])]}},{key:"cell(companyName)",fn:function({ item }){return [_c('prozess-resource-name',{staticStyle:{"width":"260px"},attrs:{"has-permission":_vm.$can('Read', 'CRM'),"to":{ name: 'company-view', params: { id: item.company.uuid, tab: _vm.GenericTabs.OVERVIEW_360 } },"title":`${item.company.name || ''}`}})]}},{key:"cell(role)",fn:function({ item }){return [(item.role)?_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(item.role)+" ")]):_vm._e()]}},{key:"cell()",fn:function({ item, field }){return [_c('span',{staticClass:"d-block",class:{ 'text-primary': field.key.includes('mobile'), 'text-truncate': field.type === 'LONGTEXT' },staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(_vm.findValue(_vm.mapValue(item, field)))+" ")])]}},{key:"cell(actions)",fn:function({ item }){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                  'icon-disabled': _vm.$can('Update', 'CRM') === false,
                },attrs:{"id":`edit-row-${item.relationshipId}-preview-icon`,"icon":"EditIcon","size":"16"},on:{"click":function($event){_vm.$can('Update', 'CRM') ? _vm.edit(item) : null}}}),_c('b-tooltip',{attrs:{"title":_vm.$can('Update', 'CRM') === true ? _vm.$t('Edit') : _vm.$t('You dont have permission to update contact'),"target":`edit-row-${item.relationshipId}-preview-icon`,"placement":"left"}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                  'icon-disabled': _vm.$can('Update', 'CRM') === false,
                },attrs:{"id":`delete-row-${item.relationshipId}-preview-icon`,"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.remove(item.relationshipId)}}}),_c('b-tooltip',{attrs:{"title":_vm.$can('Update', 'CRM') === true ? _vm.$t('Remove') : _vm.$t('You dont have permission to remove contact'),"target":`delete-row-${item.relationshipId}-preview-icon`,"placement":"left"}})],1)]}}])}),(!_vm.loading)?_c('prozess-pagination',{staticClass:"mx-2 mb-2 mt-2",class:{ 'd-none': !_vm.total },attrs:{"meta":_vm.dataMeta,"page":_vm.currentPage,"total":_vm.total,"size":_vm.perPage},on:{"change":_vm.handlePageChange}}):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }