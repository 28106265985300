<template>
  <prozess-sidebar-modal-wrapper
    id="activity-form"
    :visible="visible"
    :editing="!!resourceId"
    :loading="loading"
    :form-title="`${$t(formTitleAction)} ${$t('Activity')}`"
    :saving="saving"
    @close="close"
    @submit="save"
  >
    <form
      id="activityForm"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
    >
      <prozess-field-wrapper icon="GridIcon" :error="$hasError('categoryId')">
        <prozess-select
          v-model="form.categoryId"
          style="flex: 1"
          :placeholder="$t('Category')"
          :options="categoryOptions"
          :reduce="(option) => option.metaStatusId"
          label="metaStatusName"
          :clearable="false"
        />
      </prozess-field-wrapper>
      <prozess-field-wrapper :error="$hasError('description')">
        <label>{{ $t('Date and Time') }}</label>
        <div class="tw-flex tw-w-full">
          <b-form-datepicker
            v-model="controls.date"
            :date-format-options="{ month: 'short', weekday: 'short' }"
            :locale="$i18n.locale"
            class="tw-w-7/12"
            @keyup.enter.native="$emit('enter')"
          />
          <b-form-timepicker
            v-model="controls.time"
            placeholder="Time"
            class="tw-w-5/12"
          />
        </div>
      </prozess-field-wrapper>
      <!-- Custom Fields -->
      <div v-for="field in fieldMetadata" :key="field.key">
        <AppDynamicField
          v-model="form[field.key]"
          :field="field"
          :error="$hasError(field.key)"
          :resourceId="resourceId"
        />
      </div>

      <!-- Related users -->
      <div>
        <b-input-group
          v-if="$can('Create', 'Entity_Assignment')"
          class="input-group-merge form-sidebar__add-user"
        >
          <b-input-group-prepend is-text>
            <feather-icon icon="UserPlusIcon" />
          </b-input-group-prepend>
          <v-select
            v-model="selectedUser"
            style="flex: 1"
            :placeholder="$t('Add Related Users')"
            :options="users"
            :get-option-label="
              (option) => option.firstName + ' ' + option.lastName
            "
            @input="selectUser"
            @search="onSearchUser"
            @search:blur="onBlurSearchUser"
          >
            <span slot="no-options">
              <span v-if="searchUserText.length">{{
                $t(`No results found`)
              }}</span>
              <span v-else>{{ $t(`Search by name`) }}</span>
            </span>
          </v-select>
        </b-input-group>

        <div class="form-sidebar__users tw-pt-3 tw-mt-6">
          <div class="p-1 form-sidebar__users-header">
            <h3>{{ $t('Related users') }}</h3>
          </div>
          <div class="form-sidebar__user-list text-muted">
            <div v-if="selectedUsers.length === 0" class="p-1">
              {{ $t('No users.') }}
            </div>

            <div
              v-for="user of selectedUsers"
              :key="user.userUuid"
              class="form-sidebar__user p-1 d-flex justify-content-between"
            >
              <p>{{ `${user.firstName} ${user.lastName}` }}</p>
              <div
                v-if="$can('Delete', 'Entity_Assignment')"
                @click="removeUser(user)"
              >
                <feather-icon
                  icon="XIcon"
                  class="text-white tw-cursor-pointer bg-primary tw-rounded-full tw-flex tw-justify-center tw-items-center"
                  style="padding: 2px"
                  size="16"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import metaStatusService from '@/services/metastatus'
import activityService from '@/services/activity'
import { activitySchema, activity } from '@/schema/activity'
import fieldMixins, { customFieldsMixins } from '@/mixins/fields'
import advancedFieldMgmt from '@/services/advancedFieldMgmt'
import AppDynamicField from '@/components/shared/AppDynamicField.vue'
import { fillableOnly } from '@/helpers/field'
import { renameKeys } from 'lodash-contrib'
import { debounce } from 'lodash'
import employeeService from '@/services/employee'
import vSelect from 'vue-select'
import dayjs from 'dayjs'
import { advancedFieldValue } from '@/helpers/customFields'

const initialForm = {
  categoryId: null,
  relatedUserIds: [],
  dateTime: null,
}

const staticField = {
  categoryId: 'categoryId',
  dateTime: 'date_time',
  relatedUserIds: 'relatedUserIds',
}

const defaultControls = {
  date: dayjs().format('YYYY-MM-DD'),
  time: dayjs().format('HH:mm'),
}

export default {
  name: 'ActivityForm',
  components: { AppDynamicField, vSelect },
  mixins: [fieldMixins, customFieldsMixins],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      form: this.$lodash.cloneDeep(initialForm),
      controls: this.$lodash.cloneDeep(defaultControls),
      options: [],
      standardKeyMapping: {
        categoryId: 'categoryId',
        dateTime: 'date_time',
      },
      selectedUser: null,
      selectedUsers: [],
      users: [],
      serviceName: activityService,
      searchUserText: '',
      saving: false,
      loading: true,
      errors: [],
      fieldMetadata: [],
      categoryOptions: [],
    }
  },
  computed: {
    formTitleAction() {
      return this.resourceId ? 'Edit' : 'Add New'
    },

    dateTime() {
      return dayjs(`${this.controls.date} ${this.controls.time}`).format(
        'YYYY-MM-DDTHH:mmZ',
      )
    },
  },

  watch: {
    visible: {
      async handler(visible) {
        if (visible) {
          this.reset()
          this.loading = true
          await this.getAllCategories()
          await this.getAllFields()

          this.loading = false
        }
      },

      immediate: true,
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    async getData() {
      const { response } = await this.$async(
        activityService.getOne(this.resourceId),
      )

      this.selectedUsers = response.data.relatedUsers

      this.formatAsFormData(response.data, 'customFieldDataResponse')
    },

    async selectUser(user) {
      user.name = `${user.firstName} ${user.lastName}`
      this.searchUserText = ''
      this.selectedUser = null
      const isExisting = this.selectedUsers.find(
        userFInd => userFInd.userUuid === user.userUuid,
      )
      if (isExisting) return this.showWarning('User already exist')

      this.selectedUsers.push(user)
    },

    onSearchUser(search, loading) {
      this.searchUserText = search
      if (search.length) {
        loading(true)
        this.searchUser(search, loading, this)
      }
    },

    searchUser: debounce(async (search, loading, vm) => {
      const res = await employeeService.searchEmployee(search)
      vm.users = res.data
      loading(false)
    }, 500),

    onBlurSearchUser() {
      this.users = []
    },

    async removeUser(user) {
      this.selectedUsers = this.selectedUsers.filter(
        userFilter => userFilter.userUuid !== user.userUuid,
      )
    },

    async getAllFields() {
      const { response } = await this.$async(
        advancedFieldMgmt.getFieldsByEntity({
          schema: 'crm',
          table: 'activities',
        }),
      )

      const fieldMetadata = fillableOnly(response.data)
      // Force $set all fields to `form` object to make them reactive for v-models

      const filteredDuplicated = fieldMetadata.filter(
        fieldFilter => !Object.values(staticField).some(val => val === fieldFilter.key),
      )

      filteredDuplicated.forEach(field => {
        if (field.type === 'BOOL') {
          this.$set(this.form, field.key, false)
        } else this.$set(this.form, field.key, null)
      })

      this.fieldMetadata = this.$lodash.cloneDeep(filteredDuplicated)

      if (this.resourceId) await this.getData()
    },

    async getAllCategories() {
      const { response } = await this.$async(
        metaStatusService.getCategorybyId('crm', 'activities'),
      )

      this.categoryOptions = (response?.data?.pageItems || []).map(
        (item, index) => ({
          ...item,
          index,
        }),
      )
    },

    reset() {
      this.errors = []
      this.selectedUsers = []
      this.form = this.$lodash.cloneDeep(initialForm)
      this.saving = false
    },

    async save() {
      this.errors = await this.yupValidate(
        activitySchema(this.fieldMetadata),
        this.form,
      )

      const standardErrors = await this.yupValidate(activity, {
        categoryId: this.form.categoryId,
      })
      this.errors = [...this.errors, ...standardErrors]
      if (this.errors.length) {
        return
      }

      this.saving = true
      await this.$async(
        this.makeRequest({
          contactUuid: this.$route.params.id,
          dateTime: this.dateTime,
          relatedUserIds: this.selectedUsers.map(user => user.userUuid),
          ...this.formatAsPayload(),
        }),
      )
      this.saving = false
      this.$emit('saved')
      this.close()
    },
  },
}
</script>

<style lang="scss">
#activity-form {
  bdi {
    color: white !important;
  }
}
</style>
